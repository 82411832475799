/**
 * Format emails to be consistent.
 */
export const formatEmail = (input: string): string =>
{
	let response = input;
	response     = response.toLowerCase();
	response     = response.trim();
	
	return response;
}
