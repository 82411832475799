import { RootState }   from '@/redux/store';
import { UserClient }  from '@prisma/client';
import { useSelector } from 'react-redux';
import { SingleOptionType } from '@/components/Dropdown/Single';
import { AjaxResponseType } from '@/helpers/ajax';
import { buildOption } from '@/helpers/dropdown';
import { FAILED, SUCCESS } from '@/helpers/enum';
import { UserRole } from '@prisma/client';

const PASSWORD = {
	minLength:      6,
	requiresSymbol: true,
}

export const ROLES = [
	UserRole.USER,
	UserRole.ADMIN,
];

export const roleRequiredOptions: SingleOptionType[] = ROLES.map(entry => { return buildOption(entry)});


/**
 * Validation for the email.
 */
export const isEmailValid = (input: string): AjaxResponseType =>
{
	let response = {
		message: '',
		status:  SUCCESS,
	};
	
	const emailRegex = new RegExp('(?=.*[!@#$%^&*])');
	
	// Requires a symbol
	if (!emailRegex.test(input))
	{
		response.message = `Please provide a valid email address.`;
		response.status  = FAILED;
	}
	
	return response;
}

/**
 * Validation for the password.
 */
export const isPasswordValid = (input: string): AjaxResponseType =>
{
	let response = {
		message: '',
		status:  SUCCESS,
	};
	
	const symbolRegex = new RegExp('(?=.*[!@#$%^&*])');
	
	if (input.length < PASSWORD.minLength)
	{
		response.message = `Minimum password length is ${PASSWORD.minLength}.`;
		response.status  = FAILED;
	}
	
	// Requires a symbol
	else if (PASSWORD.requiresSymbol && !symbolRegex.test(input))
	{
		response.message = `The password must have a symbol (!@#$%^&*).`;
		response.status  = FAILED;
	}
	
	return response;
}


/**
 * Check whether the user is a General Mills (or an admin) user.
 */
export const isGeneralMills = (): boolean =>
{
	const {user} = useSelector((state: RootState) => state.auth);
	return (UserClient.EMS === user.client || UserClient.GENERAL_MILLS === user.client);
}

/**
 * Check whether the user is a Tesco Bank (or an admin) user.
 */
export const isTescoBank = (): boolean =>
{
	const {user} = useSelector((state: RootState) => state.auth);
	return (UserClient.EMS === user.client || UserClient.TESCO_BANK === user.client);
}
