import CryptoJS from 'crypto-js';
import sha256 from 'crypto-js/sha256';

/**
 * Encrypt an encoding string using a salt
 */
export const getEncrypt = (value: string, salt: string, appSalt: string = ''): string =>
{
	const customSalt = `${appSalt}-${salt}`;
	return CryptoJS.AES.encrypt(value, customSalt)
		.toString();
}

/**
 * Decrypt an encoding string using a salt
 */
export const getDecrypt = (value: string, salt: string, appSalt: string = ''): string =>
{
	const customSalt = `${appSalt}-${salt}`;
	return CryptoJS.AES.decrypt(value, customSalt)
		.toString(CryptoJS.enc.Utf8);
}

/**
 * Create a random string and return a number of characters from it
 */
export const getRandom = (length: number): string => Math.random()
	.toString(36)
	.substr(0, length);

/**
 * Encrypt a string using sha256
 */
export const getSha256 = (value: string): string => sha256(value)
	.toString();
