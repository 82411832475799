import { SingleOptionType } from '@/components/Dropdown/Single';
import { toProperCase }     from '@/helpers/string';

/**
 * Function to convert a selected option similar to a Dropdown option.
 * Only works with options created with this similar method (enums).
 */
export const buildOption = (input: string): SingleOptionType => ({
	label: toProperCase(input),
	key:   input,
	value: input,
})
