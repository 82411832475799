import { getColor }                               from '@/helpers/theme';
import { getDomain }                              from '@/helpers/url';
import { RootState }                              from '@/redux/store';
import { IconButton, useClipboard, useColorMode } from "@chakra-ui/react"
import { ShareAlternative }                       from '@styled-icons/entypo';
import { useRouter }                              from 'next/router';
import { useEffect, useState }                    from 'react';
import { useSelector }                            from 'react-redux';
import styled                                     from 'styled-components'

const LinkThemed = styled(ShareAlternative)`
	color: ${props => props.getColor(props.colorMode, 'white', ['background', 'base'])};
	width: 20px;
`;

const Share = () =>
{
	const {colorMode}         = useColorMode();
	const router              = useRouter();
	const [value, setValue]   = useState('');
	const {hasCopied, onCopy} = useClipboard(value);
	
	useEffect(() =>
	{
		if (router.isReady)
		{
			setValue(getDomain() + router.pathname + '?pref=' + router.query.pref);
		}
	}, [router.isReady, router.pathname, router.query]);
	
	return (
		<IconButton aria-label='Copy this page settings to share'
		            alignSelf='right'
		            onClick={onCopy}
		            icon={<LinkThemed colorMode={colorMode}
		                              getColor={getColor}/>}
		/>
	);
}

export default Share;
