import { DASHBOARD_HOME, MAINTENANCE_COOKIE_NAME } from '@/app/app';
import { Maintenance }                             from '@/components/Maintenance';
import { ACTIVE, FAILED, IDLE, INACTIVE, SUCCESS } from '@/helpers/enum';
import { setCookie }                               from '@/helpers/libs/nookies';
import { isBypassMaintenance, isMaintenance }      from '@/helpers/maintenance';
import { getLayout as getLayoutApp }               from '@/layouts/_App';
import { checkSession }                            from '@/redux/slices/auth';
import store, { RootState }                        from '@/redux/store';
import isUndefined                                 from 'lodash/isUndefined';
import { useRouter }                               from 'next/router';
import { useEffect, useState }                     from 'react';
import { useSelector }                             from 'react-redux';

// On a logged-in required page, we first check the session
store.dispatch(checkSession());

const LayerLoggedIn = ({children}) =>
{
	const router                                = useRouter();
	const auth                                  = useSelector((state: RootState) => state.auth);
	const [showMaintenance, setShowMaintenance] = useState(IDLE);
	
	useEffect(() =>
	{
		// Once the router is ready
		if (router.isReady)
		{
			// Maintenance hasn't been processed yet
			if (IDLE === showMaintenance)
			{
				// Maintenance mode
				if (isMaintenance())
				{
					// Bypass maintenance - set the cookie
					if (!isUndefined(router.query['maintenance']))
					{
						// Set the cookie (expires in 2 hours)
						setCookie({
							maxAge: 1 * 60 * 60,
							name:   MAINTENANCE_COOKIE_NAME,
							value:  'true',
						});
					}
					
					// Check if this user should see maintenance mode
					const userMaintenance = isBypassMaintenance() ? INACTIVE : ACTIVE;
					
					// Set to show the cookie
					setShowMaintenance(userMaintenance);
				}
				
				// Not maintenance mode
				else
				{
					setShowMaintenance(INACTIVE);
				}
			}
			
			// Not in maintenance mode - Auth has passed
			if ((IDLE === showMaintenance || INACTIVE === showMaintenance) && SUCCESS === auth.ajaxStatus)
			{
				// User needs to login
				if (FAILED === auth.status && '/' !== router.pathname)
				{
					router.push({pathname: '/', query: router.query});
				}
				
				// User already logged in but on log in page
				else if (SUCCESS === auth.status && '/' === router.pathname)
				{
					router.push({pathname: DASHBOARD_HOME, query: router.query});
				}
			}
		}
	}, [router.isReady, auth.ajaxStatus, auth.status]);
	
	return (
		<>
			{IDLE === showMaintenance && <span>Please wait...</span>}
			{ACTIVE === showMaintenance && <Maintenance/>}
			{INACTIVE === showMaintenance && SUCCESS !== auth.ajaxStatus && <span>Verifying access...</span>}
			{INACTIVE === showMaintenance && SUCCESS === auth.ajaxStatus && children}
		</>
	);
}

export const getLayout = page => getLayoutApp(<LayerLoggedIn>{page}</LayerLoggedIn>);

export default LayerLoggedIn;
