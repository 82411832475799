import { Column, Container, Row } from '@/components/Bootstrap';
import { ReactNode }              from 'react';

type ContentBlockType = {
	children?: ReactNode,
}

const ContentBlock = ({children} : ContentBlockType) =>
{
	return (
		<Container>
			<Row>
				<Column overrides={{py: '10px'}}>
					{children}
				</Column>
			</Row>
		</Container>
	);
}

ContentBlock.defaultProps = {
	children: undefined,
}

export default ContentBlock;
