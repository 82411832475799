import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import { Box, useColorMode } from "@chakra-ui/react"

type ColorModeType = {
	overrides: object
}

const ColorMode = ({overrides}: ColorModeType) =>
{
	const {colorMode: currentColorMode, toggleColorMode} = useColorMode();
	
	return (
		<Box as='button'
		     onClick={toggleColorMode}
		     {...overrides}
		>
			{'light' === currentColorMode ? <SunIcon/> : <MoonIcon/>}
		</Box>
	);
}

ColorMode.defaultProps = {
	overrides: {},
}

export default ColorMode;
