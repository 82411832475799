import { AjaxType, FAILED, IDLE } from '@/helpers/enum';

export type AjaxResponseType = {
	body?: any,
	message: string,
	status: AjaxType
}

/**
 * Default idle response used in React UseState
 */
export const idleState = {
	status:  IDLE,
	message: '',
}

/**
 * Default AJAX response when an unexpected error occurred
 */
export const errorResponse = {
	body:    {},
	status:  FAILED,
	message: 'An unexpected error occurred, please try again.',
}

/**
 * Default AJAX response, show an alert as an unhandled error occurred
 */
export const defaultResponse = {
	body:    {},
	status:  FAILED,
	message: 'An error occurred, please try again.',
}
