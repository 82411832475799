import { CONFIG } from '@/app/app';
import { formatEmail } from '@/app/format';
import { isEmailValid, isPasswordValid } from '@/app/user';
import { AjaxAlert } from '@/components/Alert';
import { Column, Container, Row } from '@/components/Bootstrap';
import { Box } from '@/components/Box';
import { Button } from '@/components/Button';
import { errorResponse, idleState } from '@/helpers/ajax';
import { FAILED, SUCCESS } from '@/helpers/enum';
import { post } from '@/helpers/libs/axios';
import { getEncrypt, getRandom } from '@/helpers/libs/crypto';
import { getLayout as getLayoutLoggedIn } from '@/layouts/LoggedIn';
import { logIn } from '@/redux/slices/auth';
import { FormControl, FormLabel, Heading, Input } from "@chakra-ui/react"
import { NextLayoutPage } from 'next';
import Image from 'next/image'
import NextLink from 'next/link';
import { useRouter } from 'next/router'
import { setCookie } from 'nookies';
import * as OTPAuth from 'otpauth';
import { useState } from 'react';
import { QRCode } from "react-qr-svg";
import { useDispatch } from 'react-redux';

const STATE_CREDENTIALS  = 'credentials';
const STATE_TFA_SETUP    = 'tfaSetup';
const STATE_TFA_VALIDATE = 'tfaValidate';

const PageIndex: NextLayoutPage = () =>
{
	const dispatch                      = useDispatch();
	const router                        = useRouter();
	const [state, setState]             = useState(STATE_CREDENTIALS);
	const [userId, setUserId]           = useState(0);
	const [email, setEmail]             = useState('');
	const [password, setPassword]       = useState('');
	const [tfaSecret, setTfaSecret]     = useState('');
	const [tfaSetup, setTfaSetup]       = useState('');
	const [tfaValidate, setTfaValidate] = useState('');
	const [ajaxAlert, setAjaxAlert]     = useState(idleState);
	
	const totpConfig = {
		issuer:    '8MS',
		label:     'Health Check',
		algorithm: 'SHA512',
		digits:    6,
		period:    30,
		secret:    OTPAuth.Secret.fromUTF8(tfaSecret),
	};
	
	const totp = new OTPAuth.TOTP(totpConfig);
	
	/**
	 * Reset Password
	 */
	const handleResetPassword = async (event) =>
	{
		event.preventDefault();
		
		const emailValid = isEmailValid(email);
		
		// Check email validation
		if (FAILED === emailValid.status)
		{
			setAjaxAlert(emailValid);
		}
		
		else
		{
			await post({
				data:      {
					input: {
						email: email,
					},
				},
				onError:   response => {setAjaxAlert(errorResponse)},
				onSuccess: response =>
				           {
					           setAjaxAlert({message: response.data.message, status: response.data.status});
				           },
				url:       '/api/auth/emailResetPassword',
			});
		}
		
		return false;
	}
	
	const handleSubmit = async (event) =>
	{
		event.preventDefault();
		
		// Credential Authentication
		if (STATE_CREDENTIALS === state)
		{
			const emailValid    = isEmailValid(email);
			const passwordValid = isPasswordValid(password);
			
			// Check email validation
			if (FAILED === emailValid.status)
			{
				setAjaxAlert(emailValid);
			}
			
			// Check password validation
			else if (FAILED === passwordValid.status)
			{
				setAjaxAlert(passwordValid);
			}
			
			// Password is valid
			else
			{
				// Create a salt to encode the user's password
				const passwordSalt = getRandom(8);
				
				// Encode the password using the salt
				const encodedPassword = getEncrypt(password, passwordSalt, CONFIG.dashboard.id);
				
				await post({
					data:      {
						input: {
							email:           email,
							encodedPassword: encodedPassword,
							salt:            passwordSalt,
						},
					},
					onError:   response => {setAjaxAlert(errorResponse)},
					onSuccess: response =>
					           {
						           setAjaxAlert({message: response.data.message, status: response.data.status});
						
						           if (SUCCESS === response.data.status)
						           {
							           // Update the user id
							           setUserId(response.data.body.id);
							
							           // User needs to setup TFA
							           if (null === response.data.body.tfa)
							           {
								           const genTfaSecret = getRandom(8);
								           setTfaSecret(genTfaSecret);
								           setState(STATE_TFA_SETUP);
							           }
							
							           // User needs to verify TFA
							           else
							           {
								           setTfaSecret(response.data.body.tfa);
								           setState(STATE_TFA_VALIDATE);
							           }
						           }
					           },
					url:       '/api/auth/checkCredentials',
				});
			}
		}
		
		// Two Factor Authentication
		else
		{
			// Check if Tfa is valid
			const isTfaSetupValid    = STATE_TFA_SETUP === state && totp.generate() === tfaSetup;
			const isTfaValidateValid = STATE_TFA_VALIDATE === state && totp.generate() === tfaValidate;
			
			// If set up TFA or validating TFA is not valid
			if (!isTfaSetupValid && !isTfaValidateValid)
			{
				setAjaxAlert({message: 'One time code does not match, please try again.', status: FAILED});
			}
			
			// Success
			else
			{
				await post({
					data:      {
						input: {
							action:    state,
							userId:    userId,
							tfaSecret: tfaSecret,
						},
					},
					onError:   response => {setAjaxAlert(errorResponse)},
					onSuccess: response =>
					           {
						           setAjaxAlert({message: response.data.message, status: response.data.status});
						
						           if (SUCCESS === response.data.status)
						           {
							           // Set the cookie (expires in 1 hour)
							           setCookie(null, CONFIG.cookies.auth, response.data.body.cookie.token, {
								           maxAge: 1 * 60 * 60,
								           path:   '/',
							           });
							
							           dispatch(logIn(response.data.body));
							
							           router.push({pathname: CONFIG.dashboard.home});
						           }
					           },
					url:       '/api/auth/checkTfa',
				});
			}
		}
	}
	
	return (
		<Container>
			<Row>
				<Column
					align='center'
					marginTop='1rem'
					spans={{xs: 12, md: 6, lg: 4}}
					offsets={{xs: 0, md: 3, lg: 4}}
				>
			        <Box
				        align='center'
				        p='2rem'
				        textAlign='center'
			        >
				        <Box marginBottom='2rem'>
					        <NextLink href='/'>
						        <a href='/'>
							        <Image
								        src='/logos/bbc.svg'
								        width={148}
								        height={148}
							        />
						        </a>
					        </NextLink>
				        </Box>
		                <Heading
			                as='h1'
			                textStyle='h2'
		                >{CONFIG.dashboard.name}</Heading>
				        <AjaxAlert
					        setAjaxAlert={setAjaxAlert}
					        message={ajaxAlert.message}
					        status={ajaxAlert.status}
				        />
				        <Box marginTop='2rem'>
							<form onSubmit={handleSubmit}>
								 <Box>
									{
										STATE_CREDENTIALS === state &&
										<>
											<FormControl>
					                            <FormLabel>Email</FormLabel>
							                    <Input
								                    autoComplete='username'
								                    id='login-username'
								                    onChange={e => setEmail(formatEmail(e.target.value))}
								                    name='email'
								                    type='email'
								                    value={email}
							                    />
					                        </FormControl>
											<FormControl mt={6}>
					                            <FormLabel>Password</FormLabel>
					                            <Input
						                            autoComplete='current-password'
						                            id='login-password'
						                            name='password'
						                            onChange={e => setPassword(e.target.value)}
						                            type='password'
						                            value={password}
					                            />
					                        </FormControl>
										</>
									}
									 {
										 STATE_TFA_SETUP === state &&
										 <FormControl mt={6}>
				                            <FormLabel>Setup Two Factor Authentication (2FA)</FormLabel>
											<br/><br/>
											<div>
												<QRCode
													style={{width: 160}}
													value={totp.toString()}
												/>
											</div>
											<br/><br/>
				                            <Input
					                            autoComplete='one-time-code'
					                            id='login-tfa-setup'
					                            name='tfaSetup'
					                            onChange={e => setTfaSetup(e.target.value)}
					                            type='text'
					                            value={tfaSetup}
				                            />
				                        </FormControl>
									 }
									 {
										 STATE_TFA_VALIDATE === state &&
										 <FormControl mt={6}>
				                            <FormLabel>Validate Two Factor Authentication (2FA)</FormLabel>
				                            <Input
					                            autoComplete='one-time-code'
					                            id='login-tfa-validate'
					                            name='tfaValidate'
					                            onChange={e => setTfaValidate(e.target.value)}
					                            type='text'
					                            value={tfaValidate}
				                            />
				                        </FormControl>
									 }
								</Box>
								<Box marginTop='2rem'>
									{
										STATE_CREDENTIALS === state &&
										<>
											<Button
												onClick={handleSubmit}
												type='submit'
											>Log in</Button>
											<Button
												marginLeft='1rem'
												onClick={handleResetPassword}
												variant='secondary'
											>Reset Password</Button>
										</>
									}
									{
										STATE_TFA_SETUP === state &&
										<Button
											onClick={handleSubmit}
											type='submit'
										>Setup TFA</Button>
									}
									{
										STATE_TFA_VALIDATE === state &&
										<Button
											onClick={handleSubmit}
											type='submit'
										>Validate 2FA</Button>
									}
								</Box>
			                </form>
			            </Box>
			        </Box>
			</Column>
		</Row>
	</Container>
	);
}

PageIndex.getLayout = getLayoutLoggedIn;

export default PageIndex;
