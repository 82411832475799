import { TextStyleType }            from '@/app/textStyles';
import { Heading as ChakraHeading } from "@chakra-ui/react"
import { ReactNode }                from 'react';

export type HeadingType = TextStyleType & {
	children?: ReactNode,
	overrides?: object,
	tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
	truncate?: boolean,
	variant?: string,
}

const Heading = ({children, overrides, tag, textStyle, truncate, variant}: HeadingType) =>
{
	return (
		<ChakraHeading as={tag}
		               isTruncated={truncate}
		               textStyle={textStyle}
		               variant={variant}
		               {...overrides}
		>
			{children}
		</ChakraHeading>
	);
}

Heading.defaultProps = {
	children:  undefined,
	overrides: {},
	tag:       'h1',
	textStyle: 'h1',
	truncate:  false,
	variant:   '',
}

export default Heading;
