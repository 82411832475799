import { AjaxType, FAILED, INFORMATION, SUCCESS, WARNING }                from '@/helpers/enum';
import { BellIcon, CheckIcon, InfoIcon, WarningTwoIcon }                  from '@chakra-ui/icons'
import { Alert as ChakraAlert, AlertDescription, Box, CloseButton, Flex } from '@chakra-ui/react';
import isUndefined                                                        from 'lodash/isUndefined';
import { ReactNode, useState }                                            from 'react';

export type AlertType = {
	children?: ReactNode,
	overrides?: {
		alert?: object,
		close?: object,
		description?: object,
	},
	onClose?: Function,
	variant: AjaxType,
}

const Alert = ({children, onClose, overrides, variant}: AlertType) =>
{
	const [show, setShow] = useState(true);
	
	if (!show)
	{
		return <></>;
	}
	
	if (isUndefined(children))
	{
		return <></>;
	}
	
	const AlertIcon = variant =>
	{
		switch (variant)
		{
			case FAILED:
				return <WarningTwoIcon/>;
			
			case INFORMATION:
				return <InfoIcon/>;
			
			case SUCCESS:
				return <CheckIcon/>;
			
			case WARNING:
				return <BellIcon/>;
			
			default:
				return <></>;
		}
	}
	
	const handleClose = () =>
	{
		if (!isUndefined(onClose))
		{
			onClose();
		}
		
		setShow(false);
	}
	
	return (
		<Box my='0.5rem'>
			<ChakraAlert variant={variant}
			             {...overrides.alert}
			>
				<Box alignSelf='start'
				     display='flex'
				     mr='0.5rem'
				     pt='0.25rem'
				>
					<AlertIcon variant={variant}/>
				</Box>
				<Flex
					flex='1'
				>
					<AlertDescription {...overrides.description}
					>
						{children}
					</AlertDescription>
				</Flex>
				<Box alignSelf='start'>
					<CloseButton onClick={handleClose}
					             {...overrides.close}
					/>
				</Box>
			</ChakraAlert>
		</Box>
	);
}

Alert.defaultProps = {
	children:  undefined,
	onClose:   undefined,
	overrides: {},
	variant:   FAILED,
}

export default Alert;
