import { getColor }                 from '@/helpers/theme';
import { logOut }                   from '@/redux/slices/auth';
import { IconButton, useColorMode } from "@chakra-ui/react"
import { LogOutCircle }             from '@styled-icons/boxicons-regular/LogOutCircle';
import { useDispatch }              from 'react-redux';
import styled                       from 'styled-components'

const LogOutThemed = styled(LogOutCircle)`
	color: ${props => props.getColor(props.colorMode, 'white', ['background', 'base'])};
	width: 20px;
	transform: rotate(180deg);
	`;

const LogOut = () =>
{
	const {colorMode} = useColorMode();
	const dispatch    = useDispatch();
	
	const handleLogOut = async event =>
	{
		event.preventDefault();
		await dispatch(logOut());
	}
	
	return (
		<IconButton aria-label='Log out of the dashboard'
		            alignSelf='right'
		            onClick={event => handleLogOut(event)}
		            icon={<LogOutThemed colorMode={colorMode}
		                                getColor={getColor}/>}
		/>
	);
}

export default LogOut;
