import { Alert }          from '@/components/Alert';
import { AjaxType, IDLE } from '@/helpers/enum';

export type AjaxAlertType = {
	setAjaxAlert: Function,
	message: string,
	status: AjaxType
}

const AjaxAlert = ({setAjaxAlert, message, status}: AjaxAlertType) =>
{
	const handleOnClose = event =>
	{
		setAjaxAlert({message: '', status: IDLE});
	}
	
	// No message hide
	if (IDLE === status || '' === message)
	{
		return <></>;
	}
	
	return (
		<Alert onClose={handleOnClose}
		       variant={status}>
			<span>{message}</span>
		</Alert>
	);
}

AjaxAlert.defaultProps = {
	children:  undefined,
	overrides: {},
	variant:   'error',
}

export default AjaxAlert;
