import { Column, Container, Row } from '@/components/Bootstrap';
import { Footer }                 from '@/components/Footer';
import { Heading }                from '@/components/Typography';

const Maintenance = () =>
{
	return (
		<Container>
			<Row>
				<Column overrides={{padding: '3rem 0', textAlign: 'center'}}>
					<Heading textStyle='h3'>Site is currently under maintenance, please check back later.</Heading>
				</Column>
			</Row>
		</Container>
	);
}

export default Maintenance;
